<template>
  <div class="mc-container">

    <div class="header">
      <h4>Novo protocolo</h4>
      <b-button
          class="wh-button no-print"
          variant="primary"
          outline
          @click="$bvModal.show('protocol-modal')"
        >
          Novo protocolo
        </b-button>
    </div>

    <b-row>
      <b-col cols="6">
        <b-input-group>
          <b-form-input
            class="search"
            autocomplete="off"
            v-model="search"
            debounce="500"
            placeholder="Buscar protocolo..."
          />
        </b-input-group>

      </b-col>
    </b-row>

    <b-row>
      <v-protocol-table
        v-for="protocol in protocols"
        :key="protocol.id"
        :protocol="protocol"
        @update-protocols="getProtocols"
      />

      <div class="pagination-position">
        <b-pagination
          size="sm"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>
    </b-row>

    <v-protocol-modal
      :admin="true"
      :protocol="protocol"
      @clear-protocol-props="clearProtocolProps" />
  </div>
</template>

<script>
import ProtocolModal from '@/components/Protocols/ProtocolModal.vue'
import ProtocolTable from '@/components/Protocols/ProtocolTable.vue'
import { EventBus } from '@/utils/eventBus'

export default {
  name: 'Protocols',
  components: {
    'v-protocol-modal': ProtocolModal,
    'v-protocol-table': ProtocolTable,
  },
  async mounted() {
    await this.getProtocols()
    EventBus.$on('editProtocol', data => {
      this.protocol = data
    })
    EventBus.$on('reloadProtocolsAdmin', async () => await this.getProtocols())
  },
  data() {
    return {
      search:  null,
      protocols: null,
      protocol: null,
      currentPage: null,
      perPage: 3,
      totalItems: null
    }
  },
  methods: {
    async getProtocols() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getProtocols(
          'admin',
          null,
          this.search,
          null,
          null,
          null,
          null,
          this.currentPage
        )
        this.currentPage = response.data.current_page
        this.totalItems = response.data.total
        this.protocols = response.data.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    clearProtocolProps() {
      this.protocol = null
    },
  },
  watch: {
    async currentPage() {
      await this.getProtocols()
    },
    async search() {
      await this.getProtocols()
    }
  }
}
</script>

<style lang="scss" scoped>
.mc-container {
  min-height: 100vh;
  padding: 40px 20px;

  .header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h4 {
      font-weight: 700;
    }
  }

  .search {
    border-radius: 8px !important;
    margin-bottom: 20px;
  }
}
</style>
